<template>
  <div class="order-box">
    <div class="order">
      <!-- 地图 -->
      <div id="container">
        <!-- :style="[2, 4].includes(detailList.vinTaskStatus) ? 'height: 60%' : '45%'" -->
        <!-- 定位 -->
        <div class="location">
          <img src="../assets/detail/location.png" @click="clickLoction" />
        </div>
      </div>
      <!-- 线路 -->
      <div id="panel"></div>
      <!-- 配送信息 -->
      <div class="order-info">
        <!-- status -->
        <div class="order-status">
          <div class="top">
            <p class="status">
              {{ detailList.vinTaskStatus === 0 ? '停靠中' : '任务中' }}
            </p>
          </div>
          <div class="vinId">
            <div v-if="detailList.vinCode">
              无人车
              <span
                style="
                  margin-right: 0.01rem;
                  color: rgba(252, 126, 54, 1);
                  font-weight: 600;
                "
              >
                {{ detailList.vinCode }}
              </span>
              <span style="color: rgba(252, 126, 54, 1); font-weight: 600">
                ({{ detailList.vinId }})
              </span>
              为您服务
            </div>
            <div v-else>
              无人车
              <span style="color: rgba(252, 126, 54, 1); font-weight: 600">
                {{ detailList.vinId }}
              </span>
              为您服务
            </div>
          </div>
        </div>
        <!-- 站点 -->
        <div class="point">
          <p class="p1">
            {{ detailList.vinTaskStatus === 0 ? '即将前往' : '正在前往' }}
          </p>
          <p class="p2">{{ detailList.destStationName || '-' }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
import {
  deliveryDetail,
  vinRunStatusInfo,
  vehicleTaskDetails,
} from '@/api/api';
import moment from 'moment';
import { getArcMidPoint, getCenterLonLat } from '@/utils';

const userLocation =
  'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/userLocation.png';

export default {
  name: 'orderDetailApp',
  data() {
    return {
      map: null,
      detailList: {}, // 订单详情列表
      vinInfo: {}, // 车辆信息
      marker: null, //地图

      geolocation: null,
      firstInto: true, // 是否第一次拖拽
      AMap: null,
      vinBubbleFlag: true, // 车辆气泡显示
      riding: null, // 骑行数据
      autoZoom: 14, // 默认比列尺
      markerLocation: null, // 定位marker
      labelDistribution: null,
      labelSigned: null,
      firstGetLocation: true, // 第一次进入获取位置 不确定中心点
      markerList: null, // 收发icon marker
      timer: null,
      number: 0,
      showDoorFlag: false, //开关门/发车
      titleText: '',
      desText: '',
      modalFlag: '', // 开关门/发车 flag存储
      vehicleCabinetInfo: {}, // 开关顶门查询
    };
  },

  mounted: function () {
    this.$nextTick(async () => {
      await this.getDetail();
      this.timer = setInterval(() => {
        this.getDetail();
        this.number += 1;
      }, 5000);
    });
  },
  beforeDestroy: function () {
    clearInterval(this.timer);
  },
  created() {},
  methods: {
    // 地图初始化
    initMap() {
      AMapLoader.load({
        key: 'e618efe9bc3dbbbd1ddfa9d847f49d74', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Geolocation', 'AMap.Geocoder', 'AMap.Riding'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.AMap = AMap;
          this.map = new AMap.Map('container', {
            //设置地图容器id
            viewMode: '3D', //是否为3D地图模式
            zoom: 14, //初始化地图级别
            center: [116.403963, 39.915119], //初始化地图中心点位置
            resizeEnable: true, // 是否监控地图容器尺寸变化，默认值为false
            vectorMapForeign: 'Local',
            mapStyle: 'amap://styles/whitesmoke', //设置地图的显示样式
            rotateEnable: false, // 地图是否可旋转，3D视图默认为true，2D视图默认false
          });

          this.map.on('dragend', (e) => {
            this.firstInto = false;
          });

          AMap.plugin('AMap.Geolocation', () => {
            this.geolocation = new AMap.Geolocation({
              enableHighAccuracy: true, //是否使用高精度定位，默认:true
              timeout: 10000, //超过10秒后停止定位，默认：5s
              buttonPosition: 'RB', //定位按钮的停靠位置
              buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
              zoomToAccuracy: true, //定位成功后是否自动调整地图视野到定位点
            });
            this.geolocation.getCurrentPosition((status, result) => {
              if (status == 'complete') {
                this.getPersonLoc(AMap, result);
              } else {
                console.log(result, '....error');
              }
            });
          });

          // 骑行路线声明
          this.riding = new AMap.Riding({
            map: this.map,
            panel: '',
            hideMarkers: true,
            autoFitView: false,
          });

          if (this.detailList.vinTaskStatus === 1) {
            let points;
            points = getCenterLonLat(
              this.detailList && this.detailList.vinLng,
              this.detailList && this.detailList.vinLat,
              this.detailList && this.detailList.destStationLng,
              this.detailList && this.detailList.destStationLat
            );

            this.map && this.map.setCenter([points[0], points[1]], true);

            this.statusLine();
            this.getDetailInfo(this.AMap);
          } else {
            // 无任务
            this.map &&
              this.map.setCenter(
                [this.detailList.vinLng, this.detailList.vinLat],
                true
              );
            this.statusLine();
          }

          this.scale();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // 人的定位marker
    getPersonLoc(AMap, result) {
      var iconLocation = new AMap.Icon({
        size: new AMap.Size(34, 34), // 图标尺寸
        image: userLocation,
        // image:
        //   'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/my-icon.png', // Icon的图像
        imageSize: new AMap.Size(34, 34), // 根据所设置的大小拉伸或压缩图片
      });

      this.markerLocation = new AMap.Marker({
        position: new AMap.LngLat(result.position.lng, result.position.lat),
        icon: iconLocation, // 添加 Icon 实例
        zIndex: 999,
        anchor: 'bottom-center',
        offset: new AMap.Pixel(0, 0),
      });
      this.map.add(this.markerLocation);
      !this.firstGetLocation &&
        this.map.setCenter([result.position.lng, result.position.lat]);
    },

    // 点击定位按钮 人
    clickLoction() {
      console.log(0);
      this.geolocation.getCurrentPosition((status, result) => {
        console.log(status, result, '...status, result');
        if (status == 'complete') {
          this.map.remove(this.markerLocation);
          this.firstGetLocation = false;
          this.firstInto = false;
          this.getPersonLoc(this.AMap, result);
        } else {
          console.log(result, '....error');
        }
      });
    },

    // 订单详情收货人
    getDetail() {
      vehicleTaskDetails(this.$route.query.vin).then((res) => {
        this.detailList = res;

        if (this.number == 0) {
          this.initMap();
        } else {
          if (this.detailList.vinTaskStatus === 1) {
            this.markerList == null ? '' : this.map.remove(this.markerList);
            this.statusLine();
            this.getDetailInfo(this.AMap);
          } else {
            this.riding == null ? '' : this.riding.clear();
            this.marker == null ? '' : this.map.remove(this.marker);
            this.markerList == null ? '' : this.map.remove(this.markerList);
            this.statusLine();
          }
        }
      });
    },

    // 收发icon
    getDetailInfo(AMap) {
      // 收
      var iconReceiving = new AMap.Icon({
        size: new AMap.Size(38, 38), // 图标尺寸
        image:
          'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/receiving-icon.png', // Icon的图像
        imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
        imageSize: new AMap.Size(38, 38), // 根据所设置的大小拉伸或压缩图片
      });

      // 收件人
      var markerReceiving = new AMap.Marker({
        position: new AMap.LngLat(
          this.detailList.destStationLng,
          this.detailList.destStationLat
        ),
        icon: iconReceiving, // 添加 Icon 实例
        anchor: 'bottom-center',
        offset: new AMap.Pixel(0, 0),
      });
      // this.markerList = [
      //   [1, 14, 2, 4].includes(this.detailList.vinTaskStatus) && markerSend,
      //   [12, 3, 10, 2, 4].includes(this.detailList.vinTaskStatus) &&
      //     markerReceiving,
      // ];
      this.markerList = [
        this.detailList.vinTaskStatus === 1 && markerReceiving,
      ];
      console.log(this.markerList, '..this.markerList');

      this.map.add(this.markerList);
    },

    // 条件判断展示i线路
    statusLine() {
      if (this.detailList.vinTaskStatus === 1) {
        // 任务中
        this.deliveryLine();
      } else {
        // 无任务
        console.log('wurensu');
        this.toBeSignedLine();
      }
    },

    // 任务中 路线展示
    deliveryLine() {
      this.riding &&
        this.riding.search(
          [this.detailList.vinLng, this.detailList.vinLat],
          [this.detailList.destStationLng, this.detailList.destStationLat],
          (status, result) => {
            if (status === 'complete') {
              this.vinBubbleFlag && this.vinBubble(result.routes);
              console.log('绘制成功');
            } else {
              console.log('绘制失败');
            }
          }
        );
    },

    // 待签收路线展示
    toBeSignedLine() {
      this.riding &&
        this.riding.search(
          [this.detailList.vinLng, this.detailList.vinLat],
          [this.detailList.vinLng, this.detailList.vinLat],
          (status, result) => {
            if (status === 'complete') {
              this.vinBubbleFlag && this.vinBubble(result.routes);
              console.log('绘制成功--车辆信息');
            } else {
              console.log('绘制失败');
            }
          }
        );
    },

    //车辆气泡显示
    vinBubble(result) {
      var time = moment().add(result[0].time, 's').format('HH:mm');

      this.marker == null ? '' : this.map.remove(this.marker);

      //  待配送 配送中
      this.labelDistribution = `
        <div class='labelContent'>
          <p>距离收货点<span style="color: #436EFF;">
            ${
              result[0].distance < 1000
                ? result[0].distance
                : (result[0].distance / 1000).toFixed(1)
            }
            ${result[0].distance < 1000 ? 'm' : 'km'}
          </span></p>
          <p>预计<span style="color: #436EFF;"> ${time || '-'} </span>到达</p>
        </div>
      `;

      var icon = new AMap.Icon({
        size: new AMap.Size(40, 38), // 图标尺寸
        image:
          'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/car.png', // Icon的图像
        imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
        imageSize: new AMap.Size(40, 38), // 根据所设置的大小拉伸或压缩图片
      });

      this.marker = new AMap.Marker({
        position: new AMap.LngLat(
          this.detailList.vinLng,
          this.detailList.vinLat
        ),
        icon: icon, // 添加 Icon 实例

        anchor: 'bottom-center',
        offset: new AMap.Pixel(0, 0),
      });

      this.detailList.vinTaskStatus === 1 &&
        this.marker.setLabel({
          content: this.labelDistribution,
          offset: new AMap.Pixel(-75, -70),
        });

      this.map.add(this.marker);
      this.vinBubbleFlag = true;
    },

    // 计算比例尺
    scale() {
      var statusOne = AMap.GeometryUtil.distance(
        [this.detailList.vinLng, this.detailList.vinLat],
        [this.detailList.destStationLng, this.detailList.destStationLat]
      ); // 状态为1的时候
      var statueOther = AMap.GeometryUtil.distance(
        [this.detailList.vinLng, this.detailList.vinLat],
        [this.detailList.vinLng + 0.0001, this.detailList.vinLat + 0.0001]
      ); // 状态不为1的时候

      var distance =
        this.detailList.vinTaskStatus === 1 ? statusOne : statueOther;

      if (distance >= 650000) {
        this.autoZoom = 4;
      } else if (distance >= 200000) {
        this.autoZoom = 7;
      } else if (distance >= 50000) {
        this.autoZoom = 9;
      } else if (distance >= 7500) {
        this.autoZoom = 11;
      } else if (distance >= 4000) {
        this.autoZoom = 12;
      } else if (distance >= 1700) {
        this.autoZoom = 13;
      } else if (distance >= 500) {
        this.autoZoom = 14;
      } else if (distance < 500) {
        this.autoZoom = 18;
      }
      this.map.setZoom(this.autoZoom, true); //设置地图层级
    },
  },
};
</script>

<style scoped lang="scss">
.order-box {
  width: 100%;
  height: 100%;
  background: #f6f7f8;
}

.order {
  width: 100%;
  height: 100%;

  #container {
    position: relative;
    padding: 0rem;
    margin: 0rem;
    width: 100%;
    height: 60%;

    .location {
      width: 1rem;
      height: 1rem;
      position: absolute;
      right: 0.12rem;
      bottom: 0.12rem;
      z-index: 9;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .order-info {
    width: 100%;
    // height: 55%;
    background: #f6f7f8;
    box-shadow: 0 0 0.12rem rgba(0, 0, 0, 0.04);
    border-radius: 0.32rem 0.32rem 0 0;
    padding: 0.2rem;
    box-sizing: border-box;

    .order-status {
      width: 100%;
      // height: 2rem;
      background: #ffffff;
      box-shadow: 0 0 0.12rem 0 rgba(0, 0, 0, 0.04);
      border-radius: 0.24rem;
      padding: 0.3rem;
      box-sizing: border-box;

      .top {
        padding-bottom: 0.12rem;

        .status {
          font-size: 0.4rem;
          color: #222222;
          font-weight: 600;
        }
      }
      .vinId {
        display: flex;
        align-items: center;
        font-size: 0.28rem;
        color: rgba(0, 0, 0, 1);
        font-weight: 400;
      }

      .bottom-btn {
        font-size: 0.26rem;
        font-weight: 500;
        color: rgba(67, 110, 255, 1);
        vertical-align: top;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .top-door {
          display: flex;
          p {
            padding: 0.12rem 0.27rem;
            border-radius: 0.32rem;
            background: rgba(255, 255, 255, 1);
            border: 2px solid rgba(67, 110, 255, 1);
            margin-right: 0.12rem;
          }
        }
        .start {
          color: rgba(255, 255, 255, 1);
          padding: 0.13rem 0.4rem;
          border-radius: 0.32rem;
          background: rgba(67, 110, 255, 1);
        }
      }
    }

    .point {
      width: 100%;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.04);
      border-radius: 0.24rem;
      margin: 0.2rem 0 0.28rem;
      padding: 0.6rem 0;
      box-sizing: border-box;
      .p1 {
        font-size: 0.32rem;
        font-weight: 400;
        text-align: center;
        color: rgba(151, 152, 171, 1);
        padding-bottom: 0.12rem;
      }
      .p2 {
        font-size: 0.36rem;
        font-weight: 600;
        margin-top: 0.2rem;
        color: rgba(0, 0, 0, 1);
        text-align: center;
        width: 6.14rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
        margin: 0 auto;
      }
    }
  }
}
</style>
